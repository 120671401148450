import {
  call,
  put,
  take,
  takeLatest,
  takeEvery,
  delay,
  fork
} from 'redux-saga/effects';

import {
  ADD_PURCHASES_REQUEST,
  ADD_PURCHASES_SUCCESS,
  GET_ALL_PURCHASES_REQUEST,
  GET_SINGLE_PURCHASES_REQUEST,
  REMOVE_PURCHASES_FAILED,
  REMOVE_PURCHASES_REQUEST,
  REMOVE_PURCHASES_SUCCESS,
  UPDATE_PURCHASES_REQUEST,
  UPDATE_PURCHASES_SUCCESS
} from 'redux/constants/InventoryConstants/inventory.purchases.constants';
import {
  addPurchaseFailed,
  populatePurchases,
  populatePurchasesFailed,
  populateSinglePurchase,
  populateSinglePurchaseFailed
} from 'redux/actions/InventoryActions/inventory.purchase.action';
import { setSnackbar } from 'redux/actions/UIComponentActions/snackbar.action';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import api from 'utils/api';

const baseURLProd = 'http://216.10.244.34:10005/rp/api/v1';

// /*----------------------------------
//        GET SINGLE USER
// ==================================*/
export function* onFetchProductStart() {
  yield takeLatest(GET_SINGLE_PURCHASES_REQUEST, fetchProduct);
}
function* fetchProduct({ payload }) {
  try {
    if (payload) {
      const { data } = yield api.get(`/get-purchase/${payload}`);
      yield put(populateSinglePurchase(data));
    }
  } catch (err) {
    yield put(populateSinglePurchaseFailed(err.message));
  }
}

/*----------------------------------
    SYSTEM USER >> GET ALL PURCHASE          
==================================*/

// export function* getAllPurchases() {
//   yield take(GET_ALL_PURCHASES_REQUEST);
//   try {
//     const { data } = yield call(api, `/getAll-purchases`);
//     // const { data } = yield call(api, `http://localhost:3000/taxRules`);
//     yield put(populatePurchases(data));
//   } catch (error) {
//     yield put(populatePurchasesFailed(error));
//     if (error.response.status !== 404)
//       yield put(setSnackbar(true, 'error', error.message));
//   }
// }
export function* getAllPurchases() {
  yield takeLatest(GET_ALL_PURCHASES_REQUEST, fetchProducts);
}
function* fetchProducts(payload) {
  try {
    const { data } = yield call(
      api,
      `/getAll-purchases?currentPage=${payload.page + 1}&query=${
        payload.searchValue
      }&size=${payload.size}&sortField=${payload.orderBy}&sortDir=${
        payload.order
      }`
    );
    console.log(data);
    yield put(populatePurchases(data));
  } catch (error) {
    yield put(populatePurchasesFailed(error));
    if (error.response.status !== 404) {
      yield put(setSnackbar(true, 'error', error.message));
    }
  }
}
/*----------------------------------
    SYSTEM USER >> ADD PURCHASE          
==================================*/
export function* addSystemUserSaga() {
  yield takeLatest(ADD_PURCHASES_REQUEST, systemUser);
}

function* systemUser({ payload }) {
  try {
    const result = yield call(sysUserAsync, payload);
    console.log(result);
    yield put({ type: ADD_PURCHASES_SUCCESS, payload: result });
    yield put(push('/inventory/purchases'));

    // yield call(getAllPurchases);
    // yield put({ type: GET_ALL_PURCHASES_REQUEST });
    yield put(
      setSnackbar(true, 'success', 'Purchase Order Added Successfully')
    );
  } catch (error) {
    yield put(addPurchaseFailed(error));
    if (error.response.status !== 404)
      yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}

async function sysUserAsync(payload) {
  const {
    outlet,
    po_number,
    po_date,
    due_date,
    shipping_tax_name,
    supplier,
    ref_number,
    status,
    status_name,
    tax_inclusive,
    discount_is_as_percentage,
    discount_value,
    total_discount,
    total_tax,
    total_shipping_cost,
    shipping_tax_id,
    shipping_tax_rate,
    shipping_tax_amount,
    rounding_amount,
    sub_total,
    net_amount,
    note,
    created_date_time,
    last_modified_time,
    purchase_order_line_items,
    shipping_cost_ex_tax,
    cannot_be_modified
  } = payload;

  const { data } = await api.post(`/save-purchase`, {
    outlet,
    po_number,
    po_date,
    due_date,
    supplier,
    ref_number,
    status,
    status_name,
    tax_inclusive,
    discount_is_as_percentage,
    discount_value,
    total_discount,
    total_tax,
    total_shipping_cost,
    shipping_tax_id,
    shipping_tax_rate,
    shipping_tax_name,
    shipping_tax_amount,
    rounding_amount,
    sub_total,
    net_amount,
    note,
    created_date_time,
    last_modified_time,
    purchase_order_line_items,
    cannot_be_modified,
    shipping_cost_ex_tax
  });
  return data;
}

/*----------------------------------
       SYSTEM USER >> UPDATE USER        
==================================*/

export function* updateSystemUserSaga() {
  yield takeEvery(UPDATE_PURCHASES_REQUEST, updateSystemUser);
}

function* updateSystemUser({ payload }) {
  try {
    const result = yield call(updateAsyncPurchases, payload);
    yield put({ type: UPDATE_PURCHASES_SUCCESS, payload: result });

    yield put(push('/inventory/purchases'));
    yield put(setSnackbar(true, 'success', 'Purchase Updated Successfully'));
  } catch (error) {
    if (error.response.status !== 404)
      yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}

async function updateAsyncPurchases(payload) {
  const { id, entry } = payload;
  const {
    outlet,
    po_number,
    po_date,
    due_date,
    supplier,
    ref_number,
    status,
    status_name,
    shipping_tax_name,
    tax_inclusive,
    discount_is_as_percentage,
    discount_value,
    total_discount,
    total_tax,
    total_shipping_cost,
    shipping_tax_id,
    shipping_tax_rate,
    shipping_tax_amount,
    rounding_amount,
    sub_total,
    net_amount,
    note,
    created_date_time,
    last_modified_time,
    purchase_order_line_items,
    pos_rid,
    cannot_be_modified,
    shipping_cost_ex_tax
  } = entry;
  const { data } = await api.put(`/update-purchase/${id}`, {
    id,
    outlet,
    po_number,
    po_date,
    due_date,
    supplier,
    ref_number,
    status,
    status_name,
    tax_inclusive,
    discount_is_as_percentage,
    discount_value,
    total_discount,
    total_tax,
    total_shipping_cost,
    shipping_tax_id,
    shipping_tax_name,
    shipping_tax_rate,
    shipping_tax_amount,
    rounding_amount,
    sub_total,
    net_amount,
    note,
    created_date_time,
    last_modified_time,
    purchase_order_line_items,
    pos_rid,
    cannot_be_modified,
    shipping_cost_ex_tax
  });
  console.log(data, 'data sagaMiddleware');
  return data;
}

/*-----------------------------------------
    TAX RATE >> DELETE SINGLE TAX RATE       
=========================================*/

export function* deleteSystemUserSaga() {
  while (true) {
    try {
      const { payload } = yield take(REMOVE_PURCHASES_REQUEST);
      yield call(deleteSingleSystemUsers, payload.id);
      yield put({
        type: REMOVE_PURCHASES_SUCCESS,
        payload: { id: payload.id }
      });
      yield put(setSnackbar(true, 'error', 'Purchase Delete Successfully'));
    } catch (error) {
      yield put({ type: REMOVE_PURCHASES_FAILED, payload: error });
      yield put(setSnackbar(true, 'error', 'Something Went Wrong'));
    }
  }
}

async function deleteSingleSystemUsers(id) {
  await api.delete(`/delete-purchase/${id}`);
}
