import React from 'react';
import {
  Dashboard as DashboardIcon,
  LocalMallOutlined as LocalMallOutlinedIcon,
  LineWeight as LineWeightIcon,
  Style as StyleIcon,
  GroupOutlined as GroupOutlinedIcon,
  TrendingUpOutlined as TrendingUpOutlinedIcon,
  Person as PersonIcon,
  Settings as SettingsIcon,
  MultilineChart as MultilineChartIcon
} from '@material-ui/icons';
import {
  FaCashRegister as CashRegisterIcon,
  FaFileInvoiceDollar as SalesHistoryIcon,
  FaRegSnowflake as ProductSeasonsIcon
} from 'react-icons/fa';
import { FiUsers as UsersIcon } from 'react-icons/fi';
import { GiProgression as ProgressSalesIcon } from 'react-icons/gi';
import {
  AiOutlineDropbox as ProductsIcon,
  AiOutlineTags as ProductsTagsIcon,
  AiOutlineGift as GiftIcon,
  AiOutlineDashboard as InventoryashboardIcon,
  AiOutlineBarChart as CustomReportIcon,
  AiOutlinePieChart as DetailedAnalyticsIcon
} from 'react-icons/ai';
import { SiCodesandbox as ProductTypesIcon } from 'react-icons/si';
import {
  RiShieldStarLine as ProductBrandsIcon,
  RiCurrencyLine as PurchaseIcon,
  RiStore3Line as InventoryIcon,
  RiMailSendLine as EmailTemplateIcon,
  RiAccountCircleLine as AccountIcon
} from 'react-icons/ri';
import {
  BiDollarCircle as DiscountIcon,
  BiBarcodeReader as PrintLabelIcon,
  BiTransferAlt as TransferIcon,
  BiBadgeCheck as CountStocIcon,
  BiBuildings as OutletsIcon,
  BiReceipt as ReceiptTemplateIcon
} from 'react-icons/bi';
import {
  MdLocalConvenienceStore as MyInventoryIcon,
  MdPayment as PaymentIcon,
  MdAccountBalance as GeneralStoreIcon
} from 'react-icons/md';
import { BsPersonCheck as SuppliersIcon } from 'react-icons/bs';
import {
  HiOutlineUserGroup as UserGroupIcon,
  HiOutlineReceiptTax as TaxRulesIcon
} from 'react-icons/hi';
import { DiDatabase as PaymentTypesIcon } from 'react-icons/di';
import { CgArrowsMergeAltV as IntegrationIcon } from 'react-icons/cg';
import { MdLoyalty as LoyaltyIcon } from 'react-icons/md';
const getItems = () => ({
  list: [
    {
      id: 'a',
      primary: 'Dashboard',
      icon: <DashboardIcon />,
      to: '/dashboard'
    },
    {
      id: 'b',
      primary: 'Point of Sale',
      icon: <LocalMallOutlinedIcon />,
      open: false,
      subitems: [
        {
          id: 1,
          primary: 'Progress Sale',
          icon: <ProgressSalesIcon />,
          to: '/enter-new-sale'
        },
        {
          id: 2,
          primary: 'Sales History',
          icon: <SalesHistoryIcon />,
          to: '/sales-history'
        },
        {
          id: 3,
          primary: 'Cash Register',
          icon: <CashRegisterIcon />,
          to: 'cash-register'
        }
      ]
    },
    {
      id: 'c',
      primary: 'Product',
      icon: <StyleIcon />,
      open: false,
      subitems: [
        {
          id: 1,
          primary: 'Products',
          icon: <ProductsIcon />,
          to: '/products'
        },
        {
          id: 2,
          primary: 'Categories',
          icon: <ProductTypesIcon />,
          to: '/product-types'
        },
        {
          id: 3,
          primary: 'Brands',
          icon: <ProductBrandsIcon />,
          to: '/product-brands'
        },
        {
          id: 4,
          primary: 'Tags',
          icon: <ProductsTagsIcon />,
          to: '/product-tags'
        },
        {
          id: 5,
          primary: 'Seasons',
          icon: <ProductSeasonsIcon />,
          to: '/product-seasons'
        },
        {
          id: 6,
          primary: 'Gift Cards',
          icon: <GiftIcon />,
          to: ''
        },
        {
          id: 7,
          primary: 'Discount Offer',
          icon: <DiscountIcon />,
          to: ''
        },
        {
          id: 8,
          primary: 'Print Label',
          icon: <PrintLabelIcon />,
          to: ''
        }
      ]
    },
    {
      id: 'd',
      primary: 'Inventory',
      icon: <LineWeightIcon />,
      open: false,
      subitems: [
        {
          id: 1,
          primary: 'Dashboard',
          icon: <InventoryashboardIcon />,
          to: '/inventory'
        },
        {
          id: 2,
          primary: 'My Inventory',
          icon: <MyInventoryIcon />,
          to: '/inventory/my'
        },
        {
          id: 3,
          primary: 'Purchases',
          icon: <PurchaseIcon />,
          to: '/inventory/purchases'
        },
        {
          id: 4,
          primary: 'Transfer',
          icon: <TransferIcon />,
          to: '/inventory/transfer'
        },
        {
          id: 5,
          primary: 'Count, Stocktake',
          icon: <CountStocIcon />,
          to: '/inventory/count-stockable'
        },
        {
          id: 6,
          primary: 'Suppliers',
          icon: <SuppliersIcon />,
          to: '/inventory/suppliers'
        }
      ]
    },
    {
      id: 'e',
      primary: 'Customers',
      icon: <GroupOutlinedIcon />,
      open: false,
      subitems: [
        {
          id: 1,
          primary: 'Customers',
          icon: <UsersIcon />,
          to: '/customer'
        },
        {
          id: 2,
          primary: 'Group',
          icon: <UserGroupIcon />,
          to: '/customer-groups'
        }
      ]
    },
    {
      id: 'f',
      primary: 'Reporting',
      icon: <TrendingUpOutlinedIcon />,
      open: false,
      subitems: [
        {
          id: 1,
          primary: 'Sales',
          icon: <MultilineChartIcon />,
          to: ''
        },
        {
          id: 2,
          primary: 'Inventory',
          icon: <InventoryIcon />,
          to: ''
        },
        {
          id: 3,
          primary: 'Register',
          icon: <CashRegisterIcon />,
          to: ''
        },
        {
          id: 4,
          primary: 'Payments',
          icon: <PaymentIcon />,
          to: ''
        },
        {
          id: 5,
          primary: 'Customers',
          icon: <UsersIcon />,
          to: ''
        },
        {
          id: 6,
          primary: 'Custom Reports',
          icon: <CustomReportIcon />,
          to: ''
        },
        {
          id: 7,
          primary: 'Detailed Analytics',
          icon: <DetailedAnalyticsIcon />,
          to: ''
        },
        {
          id: 8,
          primary: 'Users',
          icon: <UserGroupIcon />,
          to: ''
        }
      ]
    },
    {
      id: 'g',
      primary: 'Users',
      icon: <PersonIcon />,
      open: false,
      subitems: [
        {
          id: 1,
          primary: 'Users',
          icon: <UsersIcon />,
          to: '/users'
        },
        {
          id: 2,
          primary: 'Roster',
          icon: <UserGroupIcon />,
          to: '/roster'
        }
      ]
    },
    {
      id: 'h',
      primary: 'Store Setup',
      icon: <SettingsIcon />,
      open: false,
      subitems: [
        {
          id: 1,
          primary: 'General',
          icon: <GeneralStoreIcon />,
          to: '/general'
        },
        {
          id: 2,
          primary: 'Outlets and Registers',
          icon: <OutletsIcon />,
          to: '/outlets-and-registers'
        },
        {
          id: 3,
          primary: 'Receipt Templates',
          icon: <ReceiptTemplateIcon />,
          to: '/receipt-templates'
        },
        {
          id: 4,
          primary: 'Email Templates',
          icon: <EmailTemplateIcon />,
          to: '/email-templates'
        },
        {
          id: 5,
          primary: 'Payment Types',
          icon: <PaymentTypesIcon />,
          to: '/payment-types'
        },
        {
          id: 6,
          primary: 'Tax Rules',
          icon: <TaxRulesIcon />,
          to: '/tax-rules'
        },
        {
          id: 7,
          primary: 'Loyalty Points',
          icon: <LoyaltyIcon />,
          to: '/loyalty-points'
        },
        {
          id: 8,
          primary: 'Integrations',
          icon: <IntegrationIcon />,
          to: '/integrations'
        },
        {
          id: 9,
          primary: 'My Account',
          icon: <AccountIcon />,
          to: '/my-account'
        }
      ]
    }
  ]
});

export default getItems;
