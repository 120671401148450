import axios from 'axios';
import { getCookieData } from './functionsCustom';


const localhost ='http://localhost:10005/rp/api/v1'
const localHost = 'http://aaipaystore.com:10005/rp/api/v1'
const api = axios.create({
  baseURL: localHost
});

// Add a request interceptor
api.interceptors.request.use(
  function (config) {
    const cookieData = getCookieData();
    // Do something before request is sent
    cookieData.authenticationToken &&
      Object.assign(config.headers, {
        Authorization: 'Bearer ' + cookieData.authenticationToken
      });

    return config;
  },
  function (error) {
    // Do something with request error
    console.log(error)
    return Promise.reject(error);
  }
);

export default api;
